.problematicStyle{
    background: linear-gradient(90deg, #44ff9a -0.55%, #44b0ff 22.86%, #8b44ff 48.36%, #ff6644 73.33%, #ebff70 99.34%)
}

.mainLogo{
    width: 100% !important;
    height: 44px !important;
}

.footerLogo{
    width: 40% !important;
    height: 54px !important;
}

.logoTextOnFooter{
    font-weight: 700;
}

.howItWorksImage{
    display: flex;
    justify-content: center;
    align-items: center;
}

.microsoft4StartupsLogo{
    width: 220px !important;
}

